#services {
  padding: 55px;
}
.service {
  /* background-color: rgb(232, 233, 227); */
  width: 100%;
  padding: 0px 0px;
  height: 100vh;
}

.title h2 {
  font-size: 40px;
  margin: 30px auto;
  text-align: center;
  font-weight: 500;
  color: #195cc8;
  font-family: "Caveat";
  text-transform: uppercase;
}

.smallspan {
  text-transform: uppercase;
}

.bigspan2 {
  margin-left: 10px;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
}

.title h2 ::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 50%;
  width: 180px;
  height: 3px;
  /* background: rgb(161, 178, 177); */
  transform: translateX(-50%);
  margin-top: 10px;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

.card {
  height: 400px;
  width: 400px;
  padding: 20px 10px;
  /* background-color: rgb(232, 233, 227); */
  border-radius: 20px;
  margin: 35px;
  position: relative;
  overflow: hidden;
  text-align: center;
  box-shadow: rgb(26 24 24 / 25%) 0px 5px 10px 2px;
}
h5 {
  color: #1a448e;
  font-size: 30px;
  margin-bottom: 10px;
  font-family: "Baloo Bhai 2";
  text-transform: uppercase;
}

.pra p {
  font-size: 16px;
  line-height: 25px;
  margin: 24px;
  margin-top: 25px;
  color: black;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.card .button {
  /* background-color: transparent; */
  color: #111111;
  text-decoration: none;
  border: 2px solid black;
  font-weight: bold;
  padding: 9px 22px;
  border-radius: 30px;
  transition: 0.4s;
  text-transform: uppercase;
}
.card:hover {
  transform: scale(1.12);
  transition: 0.7s all ease;
}

.card .button:hover {
  background-color: #070b5ee5;
  border: 1px solid #f8f6f5;
  cursor: pointer;
  color: white;
}

h6 {
  color: black;
  font-size: 22px;
  text-transform: uppercase;
}

@media screen and (max-width: 850px) {
  .service {
    padding: 20px 0;
    height: auto;
  }
  .title h2 {
    font-size: 30px;
    margin: 20px auto;
  }
  .bigspan2 {
    font-size: 16px;
  }
  .box {
    flex-direction: column;
    align-items: center;
  }
  .card {
    width: 80%;
    margin: 20px 0;
  }
  .card h5 {
    font-size: 25px;
  }
  .pra h6 {
    font-size: 18px;
  }
  .pra p {
    font-size: 14px;
    line-height: 20px;
  }
  .card .button {
    padding: 8px 18px;
    font-size: 14px;
  }
}

@media screen and (max-width: 569px) {
  .title h2 {
    font-size: 25px;
    margin: 15px auto;
  }
  .bigspan2 {
    font-size: 14px;
  }
  .card {
    width: 90%;
  }
  .card h5 {
    font-size: 20px;
  }
  .pra h6 {
    font-size: 16px;
  }
  .pra p {
    font-size: 13px;
    line-height: 18px;
  }
  .card .button {
    padding: 6px 16px;
    font-size: 12px;
  }
}

@media screen and (max-width: 410px) {
  .title h2 {
    font-size: 20px;
    margin: 10px auto;
  }
  .bigspan2 {
    font-size: 12px;
  }
  .card {
    width: 100%;
  }
  .card h5 {
    font-size: 18px;
  }
  .pra h6 {
    font-size: 14px;
  }
  .pra p {
    font-size: 12px;
    line-height: 16px;
  }
  .card .button {
    padding: 5px 14px;
    font-size: 11px;
  }
}
