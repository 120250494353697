* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
a {
  text-decoration: none;
}
.header {
  margin-bottom: 40px;
  padding: 2px 10%;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: #ffffff;
  position: sticky;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 10px;
}

.logo {
  width: 150px;
  height: 30px;
}
nav ul {
  display: flex;
  align-items: center;
  padding: 5px 0;
}
nav ul li {
  display: inline-block;
  list-style: none;
  padding: 2px 15px;
  margin: 0 10px;
}

nav ul li a {
  text-decoration: none;
  color: #622c2c;
  font-size: 20px;
  font-family: "Caveat";
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 5px;
}
nav ul li a:hover {
  color: rgb(23, 78, 195);
  text-decoration: underline;
  transition: 0.5s;
  background-color: #f0f0f0;
}
.uil {
  padding: 10px;
  font-size: 25px;
}
@media only screen and (max-width: 850px) {
  .header {
    padding: 8px 5%;
  }
  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .logo {
    width: 120px;
    height: 24px;
    margin-bottom: 10px;
  }
  nav ul {
    padding: 0;
  }
  nav ul li {
    display: block;
    text-align: center;
    margin: 10px 0;
    padding: 0;
  }
  nav ul li a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 569px) {
  .logo {
    width: 100px;
    height: 20px;
  }
  nav ul li a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 410px) {
  .logo {
    width: 80px;
    height: 16px;
  }
  nav ul li a {
    font-size: 14px;
  }
}
