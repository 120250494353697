@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Jost:wght@900&family=Kalnia:wght@300&family=Sevillana&display=swap");
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

#about {
  padding: 5px;
}

.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: rgb(16, 16, 16);
  top: 0;
  bottom: 0;
  left: 60%;
  margin-left: 0px;
}

.container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 70%;
}

.container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: 4%;
  background-color: white;
  border: 4px solid #ff9f55;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.left {
  left: -20%;
}

.right {
  left: 50%;
}

.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

.right::after {
  left: -9.5%;
}

.content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
}
h2 {
  color: #11a372;
  padding: 0px 0px 20px 30px;
  font-size: 45px;
  text-transform: uppercase;
  font-family: "Caveat";
}
.section {
  padding: 10px 10px 10px 10px;
  margin: 120px 10px 50px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .timeline::after {
    left: 31px;
  }

  .container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  .container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  .left::after,
  .right::after {
    left: 15px;
  }

  .right {
    left: 0%;
  }
}
.about {
  width: 60%;
}
.first {
  font-family: "Caveat";

  font-size: 40px;
}
.second {
  font-family: "Single Day", cursive;
  font-size: 25px;
  letter-spacing: 0.15rem;
}
h3 {
  margin: 50px 50px;
  padding: 20px 20px;
  font-size: 40px;
  text-transform: uppercase;
  font-family: "Baloo Bhai 2";
  color: rgb(39, 2, 88);
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 23px;
}

.contents {
  padding: 0px 30px;
  border-left: 2px solid #bababa;
}
.contents {
  font-size: 15px;
}

.contents:hover {
  box-shadow: 0px 3px 12px 0px #ccc;
  border: 1px solid transparent;
}

@media screen and (max-width: 850px) {
  .about {
    width: 80%;
  }
  h2 {
    font-size: 35px;
  }
  .first {
    font-size: 30px;
  }
  .second {
    font-size: 20px;
  }
  h3 {
    margin: 30px 20px;
    padding: 15px;
    font-size: 30px;
  }
  .contents {
    padding: 0px 20px;
    font-size: 13px;
  }
}

@media screen and (max-width: 569px) {
  .about {
    width: 90%;
  }
  h2 {
    font-size: 30px;
  }
  .first {
    font-size: 25px;
  }
  .second {
    font-size: 18px;
  }
  h3 {
    margin: 20px 15px;
    padding: 10px;
    font-size: 25px;
  }
  .contents {
    padding: 0px 15px;
    font-size: 12px;
  }
}

@media screen and (max-width: 410px) {
  .about {
    width: 100%;
  }
  h2 {
    font-size: 25px;
  }
  .first {
    font-size: 20px;
  }
  .second {
    font-size: 15px;
  }
  h3 {
    margin: 15px 10px;
    padding: 5px;
    font-size: 20px;
  }
  .contents {
    padding: 0px 10px;
    font-size: 11px;
  }
}
