* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Arial", sans-serif;
  background-color: #f4f4f4;
}
.entry > p {
  padding: 5px 5px;
  color: rgb(39, 2, 88);
}
h1 {
  font-size: 50px;
  padding: 15px 15px;
}
.icons {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  margin: 0px 0px 0px 0px;
}
.btn {
  padding: 10px 10px;
  margin: 0px 0px 0px 10px;
}
.u1 {
  padding: 20px;
  font-size: 30px;
}
.total {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.entry {
  margin: 0px 0px;
  margin-left: 75px;
}
.entry > h1 {
  /* font-family: "Caveat"; */
  font-size: 60px;
  line-height: 1.2;
  color: #022c51;
  margin: 0 0 0 0;
  letter-spacing: 0.05em;
  text-shadow: 2px 1px #0f0e0e, 3px 2px #171616, 2px 2px #222020,
    5px 3px #100f0f, 1px 1px 0px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
}
.entry > h3 {
  margin: 0;
  font-size: 50px;
}
h6 {
  margin: 0 0 0 0;
  font-size: 10px;
}
.entry > p {
  font-size: 30px;
  font-family: "Caveat";
  text-transform: uppercase;
}
.total > div {
  width: 50%;
}

.img1 {
  margin-left: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70px;
}

.btn {
  padding: 0 20px;
  position: relative;
  overflow: hidden;
  font-size: 13px;
  color: #ffffff;
  font-weight: 700;
  letter-spacing: 0.05em;
  height: 56px;
  line-height: 52px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 2px solid #000;
  background: transparent;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 56px;
}
.end {
  display: flex;
  justify-content: center;
  align-items: center;
}
.end > img {
  height: 500px;
  width: 400px;
  background: transparent;
  box-shadow: 35px 15px rgba(0, 0, 0, 0.8);
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  object-fit: cover;
  /* clip-path: circle(42%); */
  opacity: 0.95;
  /* filter: brightness(150%); */
}
.btn2 {
  padding: 5px;
  font-size: 25px;
}
.btn3 {
  /* padding: 5px; */
  font-size: 16px;
}
img {
  filter: grayscale(0%);
}
.btn-pink {
  background-color: white;
  /* color: black; */
  /* margin-left: 0; */
  /* margin-right: 10px; */
  color: #1a448e;
  border-color: #1a448e;
  transition: 0.4s;
  box-shadow: 5px 5px 0px #0000003f;
  transform: scale(0.89);
}

.btn-pink:hover {
  transform: translateY(-3px);
}

img:hover {
  transform-origin: left;
  transition: all 1s;
  filter: grayscale(45%);
}
.img1 > a {
  color: black;
}

.end img {
  border-radius: 50%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}

.end img:hover {
  transform: scale(1.05);
}

@media only screen and (max-width: 850px) {
  .entry {
    margin-left: 0;
    text-align: center;
  }
  .entry > h1 {
    font-size: 60px;
  }
  .entry > h3 {
    font-size: 40px;
  }
  .entry > p {
    font-size: 20px;
  }
  .icons {
    padding: 10px;
  }
  .img1 {
    margin-left: 0;
    margin-top: 20px;
  }
  .end {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 569px) {
  .entry > h1 {
    font-size: 40px;
  }
  .entry > h3 {
    font-size: 30px;
  }
  .entry > p {
    font-size: 18px;
  }
  .icons {
    padding: 8px;
  }
  .img1 {
    margin-top: 15px;
  }
  .end > img {
    width: 80%;
  }
}

@media only screen and (max-width: 410px) {
  .entry > h1 {
    font-size: 30px;
  }
  .entry > h3 {
    font-size: 25px;
  }
  .entry > p {
    font-size: 16px;
  }
  .icons {
    padding: 5px;
  }
  .img1 {
    margin-top: 10px;
  }
  .end > img {
    width: 90%;
  }
}
