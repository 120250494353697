img {
  height: 70px;
  width: 70px;
}
.skills {
  display: flex;
  justify-content: center;
  align-items: center;
}
.left2,
.right2 {
  display: flex;
  flex-direction: column;
  height: 600px;
  width: 1000px;
  padding: 20px 10px;
  background-color: #ffffff;
  border-radius: 20px;
  margin: 50px;
  position: relative;
  overflow: hidden;
  text-align: center;
  box-shadow: rgb(26 24 24 / 25%) 0px 5px 10px 2px;
}

.left2 h2,
.right2 h2 {
  color: black;
  font-size: 30px;
  text-transform: uppercase;
  font-family: "Times New Roman";
}

.skills-3 p {
  font-family: "times new roman";
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 12px;
}
.skills-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.skills img {
  height: 45px;
  width: 45px;
  margin: 10px;
}

.firstdiv {
  width: 90px;
  height: 90px;
  /* border: 1px solid rgb(218, 201, 201); */
  border-radius: 50%;
  background-color: #fef4f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.firstdiv::after {
  content: "";
  position: absolute;
  width: 90px;
  height: 90px;
  line-height: 40px;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 0 0 2px #0829e2;
  transition: all 0.2s ease-in;
}

.firstdiv:hover {
  background-color: #fafbfd;
  transform: scale(1.12);
}

.firstdiv:hover:after {
  opacity: 1;
  transform: scale(1.12);
  transition-timing-function: cubic-bezier(0.17, 0.94, 0.95, 0.65);
}

.skills-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.skills-3 {
  margin: 0px 20px;
  padding: 10px 10px;
}
