* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1 {
  font-size: 35px;
  text-transform: uppercase;
  color: rgb(39, 2, 88);
}
