@import url("https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@700&family=Caveat&family=Jost:wght@900&family=Kalnia:wght@300&family=Sevillana&display=swap");

#contact {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 45px;
}

.section-header {
  text-align: center;
  margin: 0 auto;
  padding: 40px 0;
  /* letter-spacing: 6px; */
  font-family: "Baloo Bhai 2";
  font-size: 44px;
  line-height: 1.3;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.smallspan {
  color: blue;
  font-size: 14px;
  font-family: "Baloo Bhai 2";
}
.bigspan {
  color: black;
  font-size: 24px;
  font-family: "Caveat";
}

.contact-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  max-width: 840px;
  font-size: 25px;
}

.form-horizontal {
  /*float: left;*/
  max-width: 400px;
  font-family: "Lato";
  font-weight: 400;
}

.form-control,
textarea {
  width: 400px;
  padding: 15px 15px;
  margin: 12px 12px;
  color: #080808;
  letter-spacing: 1px;
  border-radius: 0.15;
}

.send-button {
  font-size: 18px;
  background-color: #fafafb;
}

.alt-send-button {
  width: 400px;
  height: 34px;
  transition: all 0.2s ease-in-out;
}

.send-text {
  display: block;
  margin-top: 10px;
  font: 700 12px "Lato", sans-serif;
  letter-spacing: 2px;
}

.alt-send-button:hover {
  transform: translate3d(0px, -29px, 0px);
}


.direct-contact-container {
  max-width: 400px;
}

form {
  margin-right: 150px;
}

.contact-list {
  list-style-type: none;
  margin-left: -30px;
  padding-right: 20px;
}

.list-item {
  line-height: 4;
  color: #aaa;
}

.contact-text {
  font: 300 18px "Lato", sans-serif;
  letter-spacing: 1.9px;
  color: #bbb;
}

.place {
  margin-left: 20px;
}

.phone {
  margin-left: 20px;
}

.gmail {
  margin-left: 20px;
}

.contact-text a {
  color: #bbb;
  text-decoration: none;
  transition-duration: 0.2s;
}

.contact-text:hover,
.contact-text a:hover {
  color: #000000;
  text-decoration: none;
}

.social-media-list {
  position: relative;
  font-size: 22px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  padding-right: 100px;
}

.social-media-list li a {
  color: #0f0f0f;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.social-media-list li {
  position: relative;
  display: inline-block;
  height: 60px;
  width: 60px;
  margin: 10px 30px;
  line-height: 60px;
  border: 2px solid rgb(24, 24, 24);
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  /* z-index: 1; */
}

.social-media-list li:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 0 0 1px #000000;
  transition: all 0.2s ease-in-out;
}

.social-media-list li:hover {
  background-color: #0c72de;
}

.social-media-list li:hover:after {
  opacity: 1;
  transform: scale(1.12);
  transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
}

hr {
  border-color: rgba(255, 255, 255, 0.6);
}

.list-item {
  width: 330px;
}

@media screen and (max-width: 850px) {
  .contact-wrapper {
    display: flex;
    flex-direction: column;
  }
  .direct-contact-container,
  .form-horizontal {
    margin: 0 auto;
  }

  .direct-contact-container {
    margin-top: 60px;
    max-width: 300px;
  }
  .social-media-list li {
    height: 60px;
    width: 60px;
    line-height: 60px;
  }
  .social-media-list li:after {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}

@media screen and (max-width: 569px) {
  .direct-contact-container,
  .form-wrapper {
    float: none;
    margin: 0 auto;
  }
  .form-control,
  textarea {
    margin: 0 auto;
  }

  .name,
  .email,
  textarea {
    width: 280px;
  }

  .direct-contact-container {
    margin-top: 60px;
    max-width: 280px;
  }
  .social-media-list {
    left: 0;
  }
  .social-media-list li {
    height: 55px;
    width: 55px;
    line-height: 55px;
    font-size: 2rem;
  }
  .social-media-list li:after {
    width: 55px;
    height: 55px;
    line-height: 55px;
  }
}

@media screen and (max-width: 410px) {
  .send-button {
    width: 99%;
  }
}
